export const flexMessage = {
  type: "flex",
  altText: "รับเลย 100฿ แค่สมัคร",
  contents: {
    "type": "bubble",
    "size": "giga",
    "body": {
      "type": "box",
      "layout": "vertical",
      "contents": [
        {
          "type": "image",
          "url": "https://img2.pic.in.th/pic/200b0b7a35c5730d00f.jpg",
          "size": "full",
          "aspectMode": "cover"
        }
      ],
      "paddingAll": "0px",
      "action": {
        "type": "uri",
        "label": "line",
        "uri": "https://lin.ee/Katrtpt"
      }
    }
  }
}